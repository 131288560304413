/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The McAnswerQueryResult model module.
 * @module model/McAnswerQueryResult
 * @version 1.0
 */
class McAnswerQueryResult {
    /**
     * Constructs a new <code>McAnswerQueryResult</code>.
     * @alias module:model/McAnswerQueryResult
     */
    constructor() { 
        
        McAnswerQueryResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>McAnswerQueryResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/McAnswerQueryResult} obj Optional instance to populate.
     * @return {module:model/McAnswerQueryResult} The populated <code>McAnswerQueryResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new McAnswerQueryResult();

            if (data.hasOwnProperty('answer')) {
                obj['answer'] = ApiClient.convertToType(data['answer'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('isCorrect')) {
                obj['isCorrect'] = ApiClient.convertToType(data['isCorrect'], 'Boolean');
            }
            if (data.hasOwnProperty('mcId')) {
                obj['mcId'] = ApiClient.convertToType(data['mcId'], 'String');
            }
            if (data.hasOwnProperty('translation')) {
                obj['translation'] = ApiClient.convertToType(data['translation'], Object);
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} answer
 */
McAnswerQueryResult.prototype['answer'] = undefined;

/**
 * @member {String} createdAt
 */
McAnswerQueryResult.prototype['createdAt'] = undefined;

/**
 * @member {String} deletedAt
 */
McAnswerQueryResult.prototype['deletedAt'] = undefined;

/**
 * @member {Number} id
 */
McAnswerQueryResult.prototype['id'] = undefined;

/**
 * @member {Boolean} isCorrect
 */
McAnswerQueryResult.prototype['isCorrect'] = undefined;

/**
 * @member {String} mcId
 */
McAnswerQueryResult.prototype['mcId'] = undefined;

/**
 * @member {Object} translation
 */
McAnswerQueryResult.prototype['translation'] = undefined;

/**
 * @member {String} updatedAt
 */
McAnswerQueryResult.prototype['updatedAt'] = undefined;






export default McAnswerQueryResult;

