export default {
	'en': {
		'classLabel': 'Class',
		'currency': 'Currency',
		'bagStoreTitle': 'Bag Purchase Store',
		'date': 'Date',
		'bagType': 'Bag Type',
		'amount': 'Amount',
		'total': 'Total',
		'purchase': 'Purchase',
		'rankChart': 'Ranking Chart',
		'bagChart': 'Bags Chart',
		'questionGameChart': 'Question Game Chart',
		'quantity': 'Quantity',
		'totalCost': 'Total Cost',
		'answeredQuestion': 'Answered Question',
		'totalEarning': 'Total Earning',
		'totalBags': 'Total Bags',
		'15minDelay':'15 mins delay',
	},
	'tc': {
		'classLabel': '班別',
		'currency': '餘額',
		'bagStoreTitle': '膠袋商店',
		'date': '日期',
		'bagType': '膠袋類別',
		'amount': '數量',
		'total': '總值',
		'purchase': '購買',
		'rankChart': '排名表',
		'bagChart': '用量表',
		'questionGameChart': '問答遊戲（分數）',
		'quantity': '數量',
		'totalCost': '總成本',
		'answeredQuestion': '已回答問題',
		'totalEarning': '總積分',
		'totalBags': '總膠袋',
        '15minDelay':'15分鐘延遲'
	}
}