/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ItemUpdateItemParam model module.
 * @module model/ItemUpdateItemParam
 * @version 1.0
 */
class ItemUpdateItemParam {
    /**
     * Constructs a new <code>ItemUpdateItemParam</code>.
     * @alias module:model/ItemUpdateItemParam
     */
    constructor() { 
        
        ItemUpdateItemParam.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ItemUpdateItemParam</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ItemUpdateItemParam} obj Optional instance to populate.
     * @return {module:model/ItemUpdateItemParam} The populated <code>ItemUpdateItemParam</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ItemUpdateItemParam();

            if (data.hasOwnProperty('availableOrgIds')) {
                obj['availableOrgIds'] = ApiClient.convertToType(data['availableOrgIds'], ['Number']);
            }
            if (data.hasOwnProperty('itemId')) {
                obj['itemId'] = ApiClient.convertToType(data['itemId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('translation')) {
                obj['translation'] = ApiClient.convertToType(data['translation'], Object);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<Number>} availableOrgIds
 */
ItemUpdateItemParam.prototype['availableOrgIds'] = undefined;

/**
 * @member {Number} itemId
 */
ItemUpdateItemParam.prototype['itemId'] = undefined;

/**
 * @member {String} name
 */
ItemUpdateItemParam.prototype['name'] = undefined;

/**
 * @member {Number} price
 */
ItemUpdateItemParam.prototype['price'] = undefined;

/**
 * @member {Object} translation
 */
ItemUpdateItemParam.prototype['translation'] = undefined;






export default ItemUpdateItemParam;

