import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { Carousel } from 'antd';
import Youtube from 'react-youtube';

import UploadPhotoModal from '../../../../components/UploadPhotoModal/UploadPhotoModal';
import FullWidthCartoonButton from '../../../../components/FullWidthCartoonButton/FullWidthCartoonButton';
import RankingTable from '../../../../components/RankingTable/RankingTable';
import CartoonModal from '../../../../components/CartoonModal/CartoonModal';

import Banner from './banner.jpg';
import UploadBanner from './upload-banner.jpg';
import './LandingPage.scss';
import scripts from './translations.js';
import {OrgUserApi} from "../../../../apiClient";


import OrgCompetitionApi from "../../../../apiClient/api/OrgCompetitionApi";

export class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadPhotoModalVisible: false,
            ableSubmissionUsers: [],
            submissionPlaylist: [],
            isModalVisible: false,
            modalMessage: '',
        }
    }

    componentDidMount(){
        this.loadOrgUser();
        this.loadSubmission();
    }

    loadOrgUser(){
        new OrgUserApi().orgUsers(window.schoolId).then((data) => {
            let ableSubmissionUsers = [];
            data.users.forEach((v) => {
                if(v.role == "user"){
                    ableSubmissionUsers.push({
                        id: v.id,
                        // name = class
                        name: v.name
                    });
                }
            });
            this.setState({ableSubmissionUsers: ableSubmissionUsers})
        }).catch((e) => {

        })
    }

    loadSubmission(){
        new OrgCompetitionApi().orgCompetitionSubmissions(window.schoolId, 1).then(({submissions}) => {
            this.setState({submissionPlaylist: submissions})
        }).catch((e) => {

        })
    }

    createCompetitionSubmission(inputData){
        // Default competition id is 1
        new OrgCompetitionApi().orgCompetitionUploadCompetitionSubmission(window.schoolId, 1, inputData.photo.file.originFileObj).then((uploadFileResult)=>{
            return new OrgCompetitionApi().orgCompetitionCreateSubmission(window.schoolId, 1, {
                applicant: inputData.name,
                userId: inputData.class,
                filePath: uploadFileResult.filePath,
            })
        }).then((data)=>{
            this.setState({uploadPhotoModalVisible: false, isModalVisible: true, modalMessage: scripts[this.props.langCode].modalCompetitionSubmissionSuccessMessage});
        }).catch((e) =>{
            this.setState({isModalVisible: true, modalMessage: scripts[this.props.langCode].modalCompetitionSubmissionErrMessage});
        })

    }

    render() {
        const { langCode, location } = this.props;
        const { uploadPhotoModalVisible, isModalVisible, modalMessage, submissionPlaylist } = this.state;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId',
            exact: true,
            strict: false
        });
        const { device, schoolId } = match.params;
        const isKiosk = device === 'kiosk';


        return (
            <div className="WebLandingPage">
                <div className="banner-section">
                    <Carousel autoplay autoplaySpeed={20000}>
                        <img src={Banner} alt=""/>
                        {
                            !isKiosk &&
                            <img className="clickable-img" src={UploadBanner} alt="" onClick={(e) => this.setState({ uploadPhotoModalVisible: true })}/>
                        }
                    </Carousel>
                </div>

                <div className="highlight-section">
                    <div className="highlight-photo-section">
                        <Carousel autoplay autoplaySpeed={8000}>

                        {
                            submissionPlaylist.map((v, i)=>{
                                return <div key={i} className="highlight-photo">
                                    <div className="description-overlay">{v.user.name} {v.applicant}</div>
                                    <img src={process.env.REACT_APP_IMAGE_URL + '/' + v.filePath} alt=""/>
                                </div>
                            })
                        }
                        </Carousel>
                    </div>
                    <div className="highlight-video-section">
                        <Youtube
                            videoId="RYxTB3xdh-Q"
                            opts={{
                                height: isKiosk ? 250 : 342,
                                width: '100%',
                                playerVars: {
                                    playlist: 'RYxTB3xdh-Q',
                                    loop: 1,
                                    rel: 0,
                                    autoplay: 1,
                                }
                            }}
                        />
                    </div>
                </div>
                <RankingTable langCode={langCode} height={isKiosk ?160 : 360}/>
                <div style={{display: 'flex',
                    flexFlow: 'row wrap', width: '100%'}}>
                    <div style={{width: isKiosk ? '100%': '50%', paddingRight: isKiosk ? 0: '5px'}}><FullWidthCartoonButton btnText={scripts[langCode].enterGame} targetUrl={`/${device}/${schoolId}/login`} /></div>
                    {!isKiosk &&
                    <div style={{width: '50%', paddingLeft:'5px'}}><FullWidthCartoonButton backgroundColor="#e3eda4" btnText={scripts[langCode].submission} onClick={()=> this.setState({ uploadPhotoModalVisible: true })} /></div>
                    }
                </div>
                <UploadPhotoModal
                    ableSubmissionUsers={this.state.ableSubmissionUsers}
                    visible={uploadPhotoModalVisible}
                    afterSubmit={this.createCompetitionSubmission.bind(this)}
                    onCancel={(e) => this.setState({ uploadPhotoModalVisible: false })}
                    title={scripts[langCode].gameInstructionTitle}
                    description={scripts[langCode].gameInstructionDescription}
                    howToUpload={scripts[langCode].howToUpload}
                />
                <CartoonModal
                    visible={isModalVisible}
                    onOk={(e) => this.setState({isModalVisible: false})}
                    onCancel={(e) => this.setState({isModalVisible: false})}
                    message={modalMessage}
                    btnText={scripts[langCode].ok}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode
})

export default connect(mapStateToProps)(LandingPage);