import React, { Component } from 'react';
import { connect } from 'react-redux';

import plasticBag1 from './plastic-bag-1.png';
import plasticBag2 from './plastic-bag-2.png';
import plasticBag3 from './plastic-bag-3.png';
import plasticBag4 from './plastic-bag-4.png';
import plasticBag5 from './plastic-bag-5.png';
import slogan from './footer-bg-slogan.png';
import './Footer.scss';

import scripts from './translations.js';

export class Footer extends Component {

    render() {
        const { langCode } = this.props;
        return (
            <div className="Footer">
                <div className="footer-section footer-section-1">
                    <div className="slogan">
                        <img src={slogan} alt=""/>
                    </div>
                    <div className="plastic-bag-wrapper">
                        <img className="plastic-bag plastic-bag-1" src={plasticBag1} alt="plastic bag 1"/>
                        <img className="plastic-bag plastic-bag-2" src={plasticBag2} alt="plastic bag 2"/>
                        <img className="plastic-bag plastic-bag-3" src={plasticBag3} alt="plastic bag 3"/>
                        <img className="plastic-bag plastic-bag-4" src={plasticBag4} alt="plastic bag 4"/>
                        <img className="plastic-bag plastic-bag-5" src={plasticBag5} alt="plastic bag 5"/>
                    </div>
                </div>
                <div className="footer-section footer-section-2">
                    <div className="footer-section-2-wrapper">
                        <p>
                            <a href="//www.reconnect.org.hk/">Reconnect Limited</a> © 2018 All Rights Reserved | Powered By <a href="//www.futureimpactlab.com">Future Impact Lab Limited</a> ｜ <a href="/about.html">About</a>
                        </p>
                    </div>
                </div>
                <div className="footer-section footer-section-3">
                    <p>{scripts[langCode]['disclaimer']}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode
})

export default connect(mapStateToProps)(Footer);