import React, {Component} from 'react';
import {connect} from 'react-redux';
import { matchPath } from 'react-router-dom';
import {DatePicker, Icon, InputNumber, Table, Tabs} from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import DeletePopconfirmWrapper from '../../../../components/DeletePopconfirmWrapper/DeletePopconfirmWrapper';
import CartoonModal from '../../../../components/CartoonModal/CartoonModal';
import RankingTable from '../../../../components/RankingTable/RankingTable'
import BagFormModal from '../../../../components/BagFormModal/BagFormModal';
import BagSelector from './components/BagSelector/BagSelector';

import ProPic from './propic.png';
import ProPicFirst from './propic-first.png';
import ProPicSecond from './propic-second.png';
import ProPicThird from './propic-third.png';
import RankingJSON from './ranking.json';
import StoreAd from './store-ad.svg';
import './StudentHelperPanelPage.scss';

import script from './translations';
import OrgShopApi from "../../../../apiClient/api/OrgShopApi";
import OrgRankingApi from "../../../../apiClient/api/OrgRankingApi";

const TabPane = Tabs.TabPane;

export class StudentHelperPanelPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bagDataset: [],
            questionDataset: [],
            //*//
            // questionDataset: _.map(_.range(0, 30), (i) => ({
            //     id: i,
            //     date: moment().subtract(i + 1, 'days').format(),
            //     answered_question: Math.ceil(Math.random() * 500)
            // })),
            availableBags: [],
            purchaseDate: moment(),
            selectedBag: undefined,
            purchaseQuantity: 1,
            isBagFormModalVisible: false,
            isCartoonModalVisible: false,
            cartoonModalMessage: '',
            selectedRecord: undefined,
            userBalance: 0,
        }
    }

    propicSelector() {
        const { location, selectedClass } = this.props;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId/:page',
            exact: true,
            strict: false
        });
        const { schoolId } = match.params;

        const targetSchool = _.find(RankingJSON, { school: schoolId.toUpperCase() });
        if (_.isEmpty(targetSchool)) {
            return ProPic;
        } else {
            const isFirst = _.indexOf(targetSchool.rankings.first, selectedClass);
            const isSecond = _.indexOf(targetSchool.rankings.second, selectedClass);
            const isThird = _.indexOf(targetSchool.rankings.third, selectedClass);

            if (isFirst !== -1) {
                return ProPicFirst;
            } else if (isSecond !== -1) {
                return ProPicSecond;
            } else if (isThird !== -1) {
                return ProPicThird;
            } else {
                return ProPic;
            }
        }
    }

    componentDidMount() {
        this.loadAvailableShopItem();
        this.loadBagOrder();
        this.loadUserBalance();
        this.loadMcStats();
    }

    loadAvailableShopItem() {
        new OrgShopApi().orgShopItems(window.schoolId).then((data) => {
            let availableBags = [];

            data.items.forEach((v) => {
                availableBags.push({
                    id: v.id,
                    label: v.name,
                    price: v.price
                })
            });

            _.sortBy(availableBags, (v)=> {return parseInt(v.label)})
            this.setState({availableBags: availableBags, selectedBag: availableBags.length > 0 ? availableBags[0].id: undefined})
        })
    }

    loadBagOrder() {
        new OrgShopApi().orgShopOrders(window.schoolId).then((data) => {
            var newBagDataset = [];

            data.orders.forEach((v) =>{
                let item = {}

                // no transaction
                if(v.tx == null) {
                    return
                }

                if(v.items == null || v.items.length <= 0) {
                    item = {
                        name: '-',
                        quantity: 1,
                        unitPrice: v.tx.amount * -1,
                    }
                } else {
                    item = v.items[0]
                }
                newBagDataset.push({
                    id: v.id,
                    date: moment(v.date).format(),
                    bag_type: item.name,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    status: v.status,
                })
            })

            this.setState({ bagDataset: newBagDataset});
        })
    }

    loadUserBalance() {
        new OrgShopApi().orgShopUserBalance(window.schoolId).then((data) => {

            this.setState({ userBalance: data.balance});
        })
    }

    loadMcStats() {
        new OrgRankingApi().orgRankingMcStats(window.schoolId).then((data) => {
            var questionDataset = [];
            if (data.mcStats == null) {
                return
            }
            data.mcStats.forEach((v) =>{
                questionDataset.push({
                    id: v.id,
                    date: v.date,
                    answered_question: v.answeredSum,
                    earnedSum: v.earnedSum,
                })
            })



            this.setState({ questionDataset: questionDataset});
        })
    }

    handlePurchase() {
        const {availableBags, purchaseDate, selectedBag, purchaseQuantity, userBalance} = this.state;
        //let newDataset = _.cloneDeep(bagDataset);

        // newDataset.push({
        //     id: bagDataset.length + 1,
        //     date: purchaseDate.format(),
        //     bag_type: selectedBag,
        //     quantity: purchaseQuantity
        // });
        // newDataset = _.sortBy(newDataset, ['date']).reverse();
        //
        // this.setState({ bagDataset: newDataset, isCartoonModalVisible: true });
        if (purchaseQuantity <= 0) {
            return
        }

        if (userBalance - purchaseQuantity * (_.find(availableBags, {id: selectedBag}) || {}).price < 0) {
            this.setState({ isCartoonModalVisible: true, cartoonModalMessage: `You don't have enough money!` });
            return
        }

        new OrgShopApi().orgShopCreateOrder(window.schoolId, {
            date: purchaseDate.format("YYYY-MM-DDTHH:mm:ssZ"),
            items: [{
                itemId: selectedBag,
                quantity: purchaseQuantity
            }]
        }).then((data) => {
            // refresh
            this.setState({ isCartoonModalVisible: true, cartoonModalMessage: 'Purchase Succeed!' });
            this.loadBagOrder();
            this.loadUserBalance();
        })
    }

    handleRemovePurchaseRecord(recordId) {
        // const {bagDataset} = this.state;
        // let newDataset = _.cloneDeep(bagDataset);
        // const targetIdx = _.findIndex(bagDataset, {id: recordId});
        //
        // newDataset.splice(targetIdx, 1);
        // this.setState({bagDataset: newDataset});

        new OrgShopApi().orgShopDeleteOrder(recordId, window.schoolId).then((data) => {
            // refresh
            this.loadBagOrder();
            this.loadUserBalance();
        })
    }

    render() {
        const {langCode, selectedClass} = this.props;
        const {bagDataset, questionDataset, availableBags, purchaseDate, selectedBag, purchaseQuantity, isBagFormModalVisible, isCartoonModalVisible, cartoonModalMessage, selectedRecord} = this.state;
        const bagColumns = [{
            title: '',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            align: 'center',
            render: () => ''
            // render: (text, record) => (<Icon className="icon-btn" type="edit" onClick={() => this.setState({
            //     isBagFormModalVisible: true,
            //     selectedRecord: record
            // })}/>)
        }, {
            title: script[langCode].date,
            dataIndex: 'date',
            align: 'center',
            render: (date, record) => moment(date).format('YYYY-MM-DD')
        }, {
            title: script[langCode].bagType,
            dataIndex: 'bag_type',
            align: 'center'
        }, {
            title: script[langCode].quantity,
            dataIndex: 'quantity',
            align: 'center',
        }, {
            title: script[langCode].totalCost,
            dataIndex: 'total_cost',
            align: 'center',
            render: (value, record) => numeral(record.quantity * record.unitPrice).format('0,0.[00]')
        }, {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
            width: 50,
            align: 'center',
            render: (text, record) => {

                if(record.status != 'pending') {
                    return
                }

                return <DeletePopconfirmWrapper langCode={langCode}
                                                onConfirm={(e) => this.handleRemovePurchaseRecord(record.id)}>
                    <Icon className="icon-btn remove-btn" type="close"/>
                </DeletePopconfirmWrapper>
            }
        }];
        const questionColumns = [{
            title: script[langCode].date,
            dataIndex: 'date',
            width: 150,
            align: 'center',
            render: (date, record) => moment(date).format('YYYY-MM-DD')
        }, {
            title: script[langCode].answeredQuestion,
            dataIndex: 'answered_question',
            width: 150,
            align: 'center'
        }, {
            title: `${script[langCode].totalEarning}(HKD$)`,
            dataIndex: 'total_earning',
            width: 150,
            align: 'center',
            render: (value, record) => numeral(record.earnedSum).format('0,0.[00]')
        }];
        const bagDistribution = _.sortBy(_.map(_.groupBy(bagDataset, 'bag_type'), (rows, key) => ({
            key,
            count: _.sumBy(rows, 'quantity')
        })), 'key');
        const bagTableFooter = () => <div className="table-footer">
            {script[langCode].totalBags} - {_.map(bagDistribution, (d) => `${d.key}: ${d.count}`).join(', ')}
        </div>;

        const bagOptions = _.map(availableBags, (bag) =>
            <BagSelector
                key={bag.id}
                label={`${bag.label} - ${numeral(bag.price).format('$0,0.[00]')}`}
                selected={selectedBag === bag.id}
                onClick={(e) => this.setState({selectedBag: bag.id})}
            />)

        return (
            <div className="StudentHelperPanelPage">
                <div className="upper-section">
                    <div className="profile-section">
                        <img className="profile-pic" src={this.propicSelector()} alt=""/>
                        <p>{script[langCode].classLabel}: {selectedClass}</p>
                        <p>{script[langCode].currency}: {numeral(this.state.userBalance).format('$0,0.[00]')}</p>
                    </div>
                    <div className="bag-store-section">
                        <div className="store-title">{script[langCode].bagStoreTitle}</div>
                        <div className="store-interface">
                            <div className="store-header"></div>
                            <img className="store-ad" src={StoreAd} alt=""/>
                            <form onSubmit={(e) => {
                                e.preventDefault()
                            }}>
                                <div className="form-item">
                                    <div className="form-label">{script[langCode].date}:</div>
                                    <div className="form-input">
                                        <DatePicker
                                            value={purchaseDate}
                                            onChange={(date, dateString) => this.setState({purchaseDate: date})}
                                        />
                                    </div>
                                </div>
                                <div className="form-item">
                                    <div className="form-label">{script[langCode].bagType}:</div>
                                    <div className="form-input">
                                        <div className="bags-container">
                                            {bagOptions}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <div className="form-label">{script[langCode].amount}:</div>
                                    <div className="form-input">
                                        <InputNumber
                                            style={{width: 191}}
                                            min={1}
                                            value={purchaseQuantity}
                                            onChange={(value) => this.setState({purchaseQuantity: value})}
                                        />
                                        {!_.isUndefined(selectedBag) && ` (${script[langCode].total}: ${numeral(purchaseQuantity * (_.find(availableBags, {id: selectedBag}) || {}).price).format('$0,0.[00]')})`}
                                    </div>
                                </div>
                                <div className="form-item">
                                    <button className="bw-btn"
                                            onClick={(e) => this.handlePurchase()}>{script[langCode].purchase}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="lower-section">
                    <Tabs defaultActiveKey="2">
                        <TabPane tab={script[langCode].rankChart} key="ranking-chart">
                            <RankingTable langCode={langCode} height={320}/>
                        </TabPane>
                        <TabPane tab={script[langCode].bagChart} key="bags-chart" key="2">
                            <Table
                                rowKey="id"
                                columns={bagColumns}
                                dataSource={bagDataset}
                                footer={bagTableFooter}
                                pagination={{
                                    pageSize: 4
                                }}
                            />
                        </TabPane>
                        <TabPane tab={script[langCode].questionGameChart} key="question-game">
                            <Table
                                rowKey="id"
                                columns={questionColumns}
                                dataSource={questionDataset}
                                pagination={{
                                    pageSize: 4
                                }}
                            />
                        </TabPane>
                    </Tabs>
                    <br/>
                    <div style={{textAlign: 'right'}}><small>{script[langCode]['15minDelay']}</small></div>
                </div>
                <BagFormModal
                    visible={isBagFormModalVisible}
                    selectedRecord={selectedRecord}
                    onCancel={(e) => this.setState({isBagFormModalVisible: false})}
                    afterSubmit={() => {
                        console.log('fetch')
                    }}
                />
                <CartoonModal
                    visible={isCartoonModalVisible}
                    message={cartoonModalMessage}
                    btnText="OK"
                    onOk={(e) => this.setState({isCartoonModalVisible: false})}
                    onCancel={(e) => this.setState({isCartoonModalVisible: false})}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode,
    selectedClass: state.student.class,
})

export default connect(mapStateToProps)(StudentHelperPanelPage);